import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import geojsonData from './countries.json';
import '../Game.css';
import { useNavigate, Link } from 'react-router-dom';
import Confetti from 'react-confetti';
import { motion, AnimatePresence } from 'framer-motion';

const Game = () => {
  const [currentAudio, setCurrentAudio] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [round, setRound] = useState(1);
  const [score, setScore] = useState(0);
  const [consecutiveCorrect, setConsecutiveCorrect] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [timer, setTimer] = useState(30);
  const currentCountryRef = useRef('');
  const audioRef = useRef(null);
  const [countryStyles, setCountryStyles] = useState({});
  const timerIdRef = useRef(null);
  const navigate = useNavigate();
  const [gameEnded, setGameEnded] = useState(false);
  const [endGameMessage, setEndGameMessage] = useState('');
  const [blink, setBlink] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const [showFinalScore, setShowFinalScore] = useState(false);
  const [roundCountdown, setRoundCountdown] = useState(3);
  const [isRoundStarted, setIsRoundStarted] = useState(false);
  const [audioError, setAudioError] = useState(false);

  const timerValueRef = useRef(timer);

  useEffect(() => {
    timerValueRef.current = timer;
  }, [timer]);

  const defaultCountryStyle = {
    fillColor: 'red',
    weight: 1,
    color: 'black',
    fillOpacity: 0.5,
    interactive: true,
    bubblingMouseEvents: true,
  };

  useEffect(() => {
    if (gameEnded || !currentAudio || !audioRef.current) return;

    const playAudio = () => {
      audioRef.current.play().catch(error => {
        console.error('Playback failed:', error);
        setAudioError(true);
      });
    };

    audioRef.current.load();
    if (isRoundStarted) {
      playAudio();
    }
  }, [currentAudio, gameEnded, isRoundStarted]);

  useEffect(() => {
    if (gameEnded) return;
    
    // Reset countdown and round state when new round starts
    setRoundCountdown(3);
    setIsRoundStarted(false);

    const countdownInterval = setInterval(() => {
      setRoundCountdown(prev => {
        if (prev <= 1) {
          clearInterval(countdownInterval);
          setIsRoundStarted(true);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [round, gameEnded]);

  useEffect(() => {
    if (gameEnded) {
      const timer = setInterval(() => {
        setRedirectCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            navigate('/leaderboard');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [gameEnded, navigate]);

  useEffect(() => {
    if (gameEnded || !isRoundStarted) return;

    setTimer(30);
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }

    timerIdRef.current = setInterval(() => {
      setTimer((t) => {
        if (t <= 5) setBlink(true);
        else setBlink(false);

        if (t === 1) {
          clearInterval(timerIdRef.current);
          handleCountryClick('');
        }
        return t - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerIdRef.current);
      setBlink(false);
    };
  }, [isRoundStarted, gameEnded]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Clear any existing game state when component mounts
    setCurrentAudio('');
    setIsCorrect(null);
    setRound(1);
    setScore(0);
    setConsecutiveCorrect(0);
    setWrongAnswers(0);
    setTimer(30);
    setGameEnded(false);
    setEndGameMessage('');
    setShowConfetti(false);
    setRoundCountdown(3);
    setIsRoundStarted(false);
    // Initial fetch
    fetchAudioClip();
    // Reset countdown when moving to next round
    return () => {
      // Cleanup when component unmounts
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []); // Empty dependency array means this runs once on mount

  const handleCorrectAnswer = () => {
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 3000);
  };

  const fetchAudioClip = async () => {
    if (gameEnded) return;
    try {
      let url;
      if (round === 1) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/audio/random/1`;
      } else if (round === 2) {
        const difficulty = Math.random() < 0.5 ? 1 : 2;
        url = `${process.env.REACT_APP_BACKEND_URL}/api/audio/random/${difficulty}`;
      } else if (round === 3) {
        const difficulty = Math.floor(Math.random() * 3) + 1;
        url = `${process.env.REACT_APP_BACKEND_URL}/api/audio/random/${difficulty}`;
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/audio/random`;
      }

      const response = await axios.get(url, { 
        withCredentials: false,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      const audio = response.data;
      if (!audio) return;

      setCurrentAudio(audio.audioURL);
      currentCountryRef.current = audio.country;
    } catch (error) {
      console.error('Error loading audio');
    }
  };

  useEffect(() => {
    if (gameEnded) return;
    fetchAudioClip();
  }, [round, gameEnded]);

  const submitScore = async () => {
    if (gameEnded) return;

    const nickname = localStorage.getItem('nickname');
    const finalScore = score;

    try {
      await axios.post('https://mighty-headland-49509-56ec7d863bb1.herokuapp.com/api/leaderboard', { nickname, score: finalScore });
      setGameEnded(true);
    } catch (error) {
      console.error('Error submitting score:', error);
    }
  };

  useEffect(() => {
    if ((wrongAnswers >= 3 || round >= 10) && !gameEnded) {
      let message = wrongAnswers >= 3
        ? "Thanks for playing!"
        : "Well played!";
      setEndGameMessage(message);
      setShowFinalScore(true);
      setRedirectCountdown(5);
      setGameEnded(true);
      
      // Submit score immediately but don't redirect yet
      submitScore();

      // Stop any ongoing timers and audio
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [wrongAnswers, round, gameEnded, score]);

  const handleCountryClick = (countryName) => {
    if (gameEnded) return;

    clearInterval(timerIdRef.current);
    const timeLeft = timerValueRef.current;

    const clickedCountryNormalized = countryName.trim().toLowerCase();
    const expectedCountryNormalized = currentCountryRef.current.trim().toLowerCase();
    const result = clickedCountryNormalized === expectedCountryNormalized;
    setIsCorrect(result);

    const newStyle = { fillColor: result ? 'green' : 'red', fillOpacity: 0.7 };
    updateCountryStyle(countryName, newStyle);

    if (result) {
      let baseScore = 100;
      let timeBonus = Math.round((timeLeft / 30) * 100);
      let totalScore = baseScore + timeBonus;

      if (consecutiveCorrect >= 1) {
        totalScore += totalScore * 0.1 * consecutiveCorrect;
      }

      setScore((prevScore) => prevScore + Math.round(totalScore));
      setConsecutiveCorrect((prev) => prev + 1);
      handleCorrectAnswer();
    } else {
      setConsecutiveCorrect(0);
      setWrongAnswers((prev) => prev + 1);
    }

    setTimeout(() => {
      updateCountryStyle(countryName, defaultCountryStyle);
      setIsCorrect(null);
      
      if (!result && wrongAnswers >= 2) {
        setGameEnded(true);
      } else {
        setRound((prevRound) => {
          const newRound = prevRound + 1;
          if (newRound >= 11) {
            setGameEnded(true);
          }
          return newRound;
        });
      }
    }, 1500);
  };

  const updateCountryStyle = (countryName, newStyle) => {
    setCountryStyles((prevStyles) => ({
      ...prevStyles,
      [countryName]: newStyle,
    }));
  };

  const ukRegions = {
    coordinates: [
      { name: "Scotland", bounds: [[54.6, -8.2], [58.6, -0.7]] },
      { name: "Wales", bounds: [[51.3, -5.3], [53.4, -2.6]] },
      { name: "Northern Ireland", bounds: [[54.0, -8.2], [55.3, -5.4]] },
      { name: "England", bounds: [[50.0, -5.7], [55.8, 1.8]] }
    ]
  };

  const isPointInBounds = (point, bounds) => {
    return point[0] >= bounds[0][0] && 
           point[0] <= bounds[1][0] && 
           point[1] >= bounds[0][1] && 
           point[1] <= bounds[1][1];
  };

  const getUKRegion = (lat, lng) => {
    const point = [lat, lng];
    for (const region of ukRegions.coordinates) {
      if (isPointInBounds(point, region.bounds)) {
        return region.name;
      }
    }
    return "England"; // Default to England if no specific region is matched
  };

  const onEachCountry = (feature, layer) => {
    const countryName = feature.properties.ADMIN;

    const getTooltipContent = (e) => {
      if (countryName === "United Kingdom") {
        const point = e.latlng;
        return getUKRegion(point.lat, point.lng);
      }
      return countryName;
    };

    // Set up initial tooltip
    layer.bindTooltip(() => countryName, {
      permanent: false,
      sticky: true,
      direction: 'auto',
      className: 'country-label',
      opacity: 0.9
    });

    layer.on({
      click: (e) => {
        if (countryName === "United Kingdom") {
          const clickedPoint = e.latlng;
          const region = getUKRegion(clickedPoint.lat, clickedPoint.lng);
          handleCountryClick(region);
        } else {
          handleCountryClick(countryName);
        }
      },
      mouseover: (e) => {
        if (countryName === "United Kingdom") {
          const content = getTooltipContent(e);
          layer.setTooltipContent(content);
        }
      }
    });
  };

  const getCountryStyle = (feature) => {
    return countryStyles[feature.properties.ADMIN] || defaultCountryStyle;
  };

  return (
    <div className="min-h-screen p-4">
      {gameEnded ? (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 p-8 rounded-lg shadow-xl max-w-md w-full mx-4">
            <div className="text-center space-y-6">
              <h2 className="text-3xl font-bold text-white">Thanks for playing!</h2>
              <div className="text-2xl font-semibold text-white">Score: {score}</div>
              <p className="text-lg text-white/80">
                Redirecting to leaderboard in {redirectCountdown}s
              </p>
              <div className="flex justify-center space-x-4 mt-6">
                <button
                  onClick={() => {
                    localStorage.removeItem('skipNicknameInput');
                    navigate('/');
                  }}
                  className="px-4 py-2 bg-white/20 hover:bg-white/30 text-white rounded transition-colors border border-white/20"
                >
                  Back Home
                </button>
                <button
                  onClick={() => {
                    const nickname = localStorage.getItem('nickname');
                    if (nickname) {
                      localStorage.setItem('skipNicknameInput', 'true');
                      window.location.reload();
                    } else {
                      navigate('/');
                    }
                  }}
                  className="px-4 py-2 bg-white/20 hover:bg-white/30 text-white rounded transition-colors"
                >
                  Play Again
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          {showConfetti && (
            <Confetti
              width={windowSize.width}
              height={windowSize.height}
              recycle={false}
              numberOfPieces={200}
            />
          )}
          
          <div className="flex flex-col space-y-4">
            <div className="bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 rounded-lg shadow-lg p-4">
              <div className="flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                <Link to="/" className="text-3xl sm:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 hover:from-gray-200 hover:to-white transition-all duration-300">
                  LangGuesser
                </Link>
                <div className="text-xl font-semibold">
                  Score: {score} | Round: {round}
                </div>
              </div>
              
              {currentAudio && (
                <div className="mt-4 space-y-3">
                  <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4">
                    {!isRoundStarted ? (
                      <div className="text-4xl font-bold text-white text-center">
                        Round {round}<br/>
                        <span className="animate-pulse">Starting in {roundCountdown}...</span>
                      </div>
                    ) : (
                      <>
                        <audio
                          ref={audioRef}
                          src={currentAudio}
                          controls
                          preload="auto"
                          playsInline
                          onError={(e) => {
                            console.error('Error with audio playback:', e);
                            setAudioError(true);
                          }}
                        />
                        {audioError ? (
                          <button
                            onClick={() => {
                              if (audioRef.current) {
                                setAudioError(false);
                                audioRef.current.currentTime = 0;
                                audioRef.current.play().catch(() => setAudioError(true));
                              }
                            }}
                            className="px-4 py-2 bg-white/20 hover:bg-white/30 text-white rounded transition-colors border border-white/20"
                          >
                            Tap to Play Audio
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              if (audioRef.current) {
                                audioRef.current.currentTime = 0;
                                audioRef.current.play().catch(() => setAudioError(true));
                              }
                            }}
                            className="w-full sm:w-auto px-6 py-2 bg-black/30 hover:bg-black/40 text-white rounded transition-colors border border-white/20"
                          >
                            Listen Again
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  {isRoundStarted && (
                    <div id="timer-display" 
                      className={`text-center text-xl font-medium ${blink ? 'blink-class' : ''}`}>
                      Time remaining: {timer} seconds
                    </div>
                  )}
                  <AnimatePresence>
                    {isCorrect !== null && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="text-center space-y-1"
                      >
                        <motion.p
                          className={`text-xl font-bold ${isCorrect ? 'text-green-400' : 'text-red-400'}`}
                          animate={!isCorrect ? {
                            x: [-10, 10, -10, 10, 0],
                            transition: { duration: 0.5 }
                          } : {}}
                        >
                          {isCorrect ? 'Correct! 🎉' : 'Incorrect 😔'}
                        </motion.p>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.2 }}
                          className="text-lg"
                        >
                          Expected country: {currentCountryRef.current}
                        </motion.div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  {endGameMessage && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-center text-xl font-medium mt-2"
                    >
                      {endGameMessage}
                    </motion.div>
                  )}
                </div>
              )}
            </div>

            <div className="bg-gradient-to-br from-purple-600/90 via-blue-500/90 to-teal-400/90 rounded-lg shadow-lg p-2">
              <MapContainer
                center={[30, 0]}
                zoom={2}
                scrollWheelZoom={true}
                style={{
                  height: 'calc(75vh - 100px)',
                  width: '100%',
                  borderRadius: '8px',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <GeoJSON data={geojsonData} style={getCountryStyle} onEachFeature={onEachCountry} />
              </MapContainer>
              <div className="flex justify-center mt-4">
                <Link to="/" className="p-[3px] relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg" />
                  <div className="px-6 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent">
                    Back to Menu
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Game;
