import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import languageData from '../data/languageDatabase.json';

const Languages = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredLanguages = languageData.languages.filter(item => {
    const query = searchQuery.toLowerCase();
    return (
      item.language.toLowerCase().includes(query) ||
      item.countries.some(country => country.toLowerCase().includes(query))
    );
  });

  return (
    <div
      className="min-h-screen flex flex-col items-center p-4"
    >
      {/* Header Section */}
      <div className="text-center mb-5 items-center p-4 bg-black/50 backdrop-blur-sm rounded-xl border border-white/10">
        <img
          className="mt-1 rounded-full mx-auto mb-1 shadow-md"
          src="/images/logo3.png"
          alt="LangGuesser"
          width={150}
          height={150}
        />
        <h1 className="text-4xl font-bold mb-1 text-white">LangGuesser's</h1>
        <p className="text-md mb-2 text-white font-semibold">Language Database</p>
        <div className="text-center mb-1 items-center p-4 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10 text-white">
          <p className="text-lg">Total audio clips: 100</p>
          <p className="italic">As of v0.5</p>
        </div>
      </div>

      {/* Search and Languages Section */}
      <div className="w-full max-w-5xl bg-black/70 backdrop-blur-sm rounded-lg p-6 shadow-lg border border-white/10">
        {/* Search Bar */}
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search by language or country..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 pl-10 bg-black/30 border border-white/20 rounded-lg focus:outline-none focus:border-white/40 text-white placeholder-gray-300"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>

        {/* Languages Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredLanguages.map((item, index) => (
            <div
              key={index}
              className="bg-black/30 p-4 rounded-lg border border-white/10 hover:border-white/20 transition-colors"
            >
              <h3 className="text-xl font-semibold mb-2 text-white">
                {item.language}
              </h3>
              <div className="text-gray-300 text-sm">
                {item.countries.map((country, idx) => (
                  <span key={idx} className="inline-block">
                    {country}
                    {idx < item.countries.length - 1 && <span className="mx-1">•</span>}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <div className="mt-6">
        <Link to="/" className="font-bold text-green-300 hover:text-green-500">
          Go back
        </Link>
      </div>
    </div>
  );
};

export default Languages;
