import React, { useEffect, useRef } from 'react';

const Roadmap = ({ isOpen, onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isOpen && modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    const handleEscKey = (e) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center p-4">
      <div
        className="rounded-lg shadow-lg bg-gradient-to-br from-green-600 via-blue-500 to-purple-500 text-white w-full max-w-3xl mx-auto opacity-95 relative"
        style={{ backdropFilter: 'blur(10px)', maxHeight: '90vh' }}
        ref={modalRef}
      >
        <button
          className="absolute top-2 right-2 text-xl font-bold text-white"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="overflow-y-auto p-6" style={{ maxHeight: '85vh' }}>
          <h2 className="text-3xl font-extrabold mb-6 text-center">Roadmap 2025</h2>
          <ul className="list-disc list-inside space-y-4 text-lg">
            <li>Implement a complaint system for general complaints and bugs.</li>
            <li>Expand the audio library with longer content.
            <span className="text-xs italic text-green-600 bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
                ongoing
            </span>
            </li>
            <li>Further UI/UX changes, with animations and sound effects.
              <span className="text-xs italic text-green-600 bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
                beta
              </span>
            </li>
            <li>
              Implement a system where users can submit their own audio clips for review.
              <div className="mt-1 p-2 rounded-lg bg-gray-800 text-white">
                <p className="italic text-sm">Users can upvote and downvote those audio clips to decide what gets into the game.</p>
              </div>
            </li>
            <li>Interactive tutorial with guided gameplay for new users to understand the mechanics.</li>
            <li>Implement user profiles.</li>
            <li>Implement a daily challenge system with unique rewards and seasonal events.</li>
            <li>
              Add multiplayer mode, starting off with 1v1 mode.
              <span className="text-xs italic text-green-600 bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
                beta
              </span>
            </li>
            <li>
                Leaderboard expansions:
                <div className="mt-1 p-2 rounded-lg bg-gray-800 text-white">
                <p className="italic text-sm">Regional leaderboards.</p>
                <p className="italic text-sm">Weekly leaderboards.</p>
              </div>
            </li>
            <li>Implement a hardcore game mode.</li>
            <li>Implement AI-generated hints for the more difficult rounds.</li>
            <li>
                Implement custom games, a preview of some of the settings:
                <div className="mt-1 p-2 rounded-lg bg-gray-800 text-white">
                <p className="italic text-sm">Allow users to adjust difficulty, time limits, and number of rounds.</p>
                <p className="italic text-sm">Practice mode with no scoring, just learning.</p>
                </div>
            </li>
            <li>Localized audio packs.</li>
            <li>Offline mode.</li>
            <li>Launch mobile app version for iOS and Android.</li>
            <li>Implement a language learning system. Users can choose a specific language to focus on and practice, with themed games tailored to that language.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
